import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

function SinglePost(item) {
  return (
    <div className="laptop:p-2 text-left  m-auto  py-5 px-6  mobile:w-full mobile:px-1">
      <li
        style={{ height: 440 + "px" }}
        className="blog-post-height desktop:max-h-full p-3  bg-gray-50 shadow-md  text-sm tablet:h-auto rounded-lg "
      >
        {<Img className="  " fluid={item.postImage.fluid} alt={item.title} />}

        <div className="pt-3 flex flex-row m-auto">
          {item.author && (
            <div className=" flex flex-row">
              <img
                className=" rounded-full w-7 h-7 mr-1.5 "
                src={item.author.profilePicture.fixed.src}
                alt={item.title}
              />
              <span className="cursor-default py-1 text-blue-600 text-xs mr-1.5">
                {item.author.name}
              </span>
            </div>
          )}

          <span className=" cursor-default mr-2 py-1 text-gray-600 text-xs ">
            on
          </span>
          <span className="text-blue-600 py-1 text-xs  mr-2">
            <Link to={`/blog/${item.category.slug}/#posts`}>
              {item.category.title}
            </Link>
          </span>
          <span className="mr-2 text-xs py-1 cursor-default  text-gray-600">
            *
          </span>
          <span className=" cursor-default text-xs py-1  text-gray-600 ">
            {item.createdAt}
          </span>
        </div>
        <p className="text-black font-bold text-xl py-3 capitalize ">
          <Link to={`/blog/${item.category.slug}/${item.slug}/`}>
            {item.title}
          </Link>
        </p>

        <p className="cursor-default text-gray-500 text-sm pb-5">
          {item.excerpt} [...]
        </p>
      </li>
    </div>
  );
}

export default SinglePost;

// prop category item.category[0]

// prop postImage item.featuredImage.fluid
