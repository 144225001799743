import React from "react";
import { graphql, StaticQuery } from "gatsby";
import SinglePost from "./post";
// import Img from "gatsby-image" ;
function blog() {
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulBlogPost(
            filter: { isFeatured: { eq: true }, node_locale: { eq: "en-US" } }
          ) {
            nodes {
              title
              excerpt {
                excerpt
              }
              slug
              author {
                profilePicture {
                  fixed(width: 60, quality: 100, height: 60) {
                    width
                    height
                    src
                    srcSet
                  }
                }
                name
              }
              createdAt(formatString: "MM/DD/YYYY")
              featuredImage {
                fluid(maxWidth: 1600) {
                  ...GatsbyContentfulFluid
                }
              }
              category {
                slug
                title
              }
              content {
                raw
                references {
                  description
                  fluid(maxWidth: 750) {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
            totalCount
          }
        }
      `}
      render={(data) => {
        return (
          <section className=" bg-gray-100">
            <div className="w-full text-center py-24 ">
              <h1 className="  text-4xl font-bold mb-5 capitalize text-black mobile:w-full">
                Featured posts
              </h1>
              <ul
                className={` px-20 grid grid-cols-3 mobile:grid-cols-1 mobile:px-4 tablet:grid-cols-1 laptop:px-8 `}
              >
                {data.allContentfulBlogPost.nodes.slice(0, 3).map((item) => (
                  <SinglePost
                    item={item}
                    key={Math.random()}
                    category={item.category[0]}
                    postImage={item.featuredImage}
                    excerpt={item.excerpt.excerpt}
                    title={item.title}
                    createdAt={item.createdAt}
                    author={item.author}
                    slug={item.slug}
                  />
                ))}
              </ul>
            </div>
          </section>
        );
      }}
    />
  );
}

export default blog;
